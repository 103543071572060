.customSelect {
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
}



.optionList {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 10px 10px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
    animation: fadeIn 0.3s ease;
}

.option {
    margin: 0px;
    /* width: 166px; */
    margin-top: 5px;
    padding: 10px 20px;
    font-size: 14px;
    display: flex ;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    /* background-color: #F8F8F8; */
    transition: background-color 0.3s ease;
    border-radius: 13px;
}

.option:hover {
    background-color: #2C2C2C  ;
    color: #FFB600;
}

.selected {
    background-color: #2C2C2C;
    color: #FFB600;
}

.selected:hover {
    background-color: #2C2C2C;
    color: #FFB600;
}

/* Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

