.slider-container {
  width: 100%;
  max-width: 100%;
  margin: auto;
  padding: 5px 20px;
}

.slider-inputs input[type='range'] {
  position: absolute;
  width: 100%;
  opacity: 0;
  pointer-events: none;
}

.slider-track {
  position: relative;
  height: 16px;
  margin-top: 20px;
}

.slider-full-track {
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #F8F8F8;
  top: 50%;
  transform: translateY(-50%);
}

.slider-range {
  position: absolute;
  height: 3px;
  background-color: #FFB600;
  top: 50%;
  transform: translateY(-50%);
}

.slider-thumb {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: black;
  border-radius: 50%;
  top: 50%;
  z-index: 100;
  transform: translateY(-50%);
}


