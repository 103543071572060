@import url('https://fonts.googleapis.com/css2?family=Objectivity:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;



.hoverAni:hover div {
  height: 2px;
  width: 20px;
  background-color: #F6B000;
  position: absolute;
  left: 60%;
}

.hoverAni1:hover div {
  height: 2px;
  width: 20px;
  background-color: #F6B000;
  position: absolute;
  left: 60%;
}

.hoverAni56:hover div {
  height: 2px;
  width: 30px;
  background-color: #F6B000;
  position: absolute;
  left: 62%;
}

.hoverAni56:hover img, .hoverAni56:hover svg {
  /* top: calc(40%-10px); */
  /* top: 12px; */
  top: 17px;
  position: absolute;
  margin-left: 9.5%;
}

.hoverAni56 img {
  transition: 0.3s all;
}

.hoverAni3:hover div {
  height: 2px;
  width: 20px;
  background-color: #F6B000;
  position: absolute;
  left: 55%;
}

.hoverAni5:hover div {
  height: 2px;
  width: 20px;
  background-color: #F6B000;
  position: absolute;
  left: 67.5%;
}

.hoverAni2:hover div {
  height: 2px;
  width: 20px;
  background-color: #F6B000;
  position: absolute;
  left: 81%;
}

.hoverAni6:hover div {
  height: 2px;
  width: 20px;
  background-color: #F6B000;
  position: absolute;
  left: 64%;
}

.button21:hover img {
  width: 35px;
  height: 24px;
}

.button21:hover span {
  margin-left: 1rem;
}

.button21 {
  transition: 0.5s all;
}

.button21 img {
  transition: 0.5s all;
}


.howGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 30px;
}

.font-700 {
  font-weight: 700 !important;
}

.detail div:hover {
  color: white;
}

.detail div {
  transition: 0.3s all;
  cursor: pointer;
}

body {
  /* max-width: !important 100vw; */
  /* overflow: !important hidden; */
}





.hoverAni:hover img, .hoverAni:hover svg {
  margin-left: 39px;
}

.hoverAni1:hover img, .hoverAni1:hover svg {
  margin-left: 49px;
}




.hoverAni5:hover img {
  margin-left: 35px;

}

.hoverAni6:hover img, .hoverAni6:hover svg, .hoverAni6:hover .svgPath {
  margin-left: 35px;
  color: #F6B000;

}

.hoverAni6:hover, .hoverAni:hover, .hoverAni3:hover, .hoverAni56:hover {
  color: #F6B000;
}

.hoverAni3:hover img, .hoverAni3:hover svg {
  margin-left: 35px;

}

.hoverAni2:hover img, .hoverAni2:hover svg {
  margin-left: 40px;

}

.hoverArrow:hover .imageInArrow{
  width: 100%;
  opacity: 1;
}

.catCard:hover .imageInCatCard {
  width: 100%;
  opacity: 1;
}

.catCard:hover .textSection {
  /* margin-left: -40px; */
}

.catContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 30px;
}




.hoverAni img, .hoverAni1 img {
  transition: 0.5s all;
}

.hoverAni6 img, .hoverAni6 svg {
  transition: 0.5s all;
}

.hoverAni5 img {
  transition: margin-left 0.5s;
}

.hoverAni2 img {
  transition: 0.5s all;
}

.hoverAni3 img {
  transition: 0.5s all;
}

/* .mainCard:hover .mainButton {
  background-color: #F6B000;

} */

.submitButton img {
  transition: 0.3 all;
  display: block;
}

.submitButton:hover img {
  height: 24px;
  width: 35px;
}



/* .submitButton:hover {
  background-color: #F6B000 !important;
} */

input,
svg,
rect,
path {
  transition: all 0.3s ease;
}


select {
  border-radius: 10px !important;

}

select option:hover,
select option:focus,
select option:active {
  background-color: #F6B000 !important;
  color: white !important;
}

select option:checked {
  background-color: #F6B000 !important;
  color: white !important;
}

select {
  appearance: none;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
}

select option:hover,
select option:focus {
  background-color: #F6B000 !important;
  outline: none;
}

/* .scrollbarCustom::-webkit-scrollbar{
  width: 10px;
} */

.featureLeftIcon:hover .featureLeftIconInner {
  margin-left: -40px;

}

.featureLeftIcon:hover .featureRightInner {
  margin-left: 40px;
}

.featureLeftIcon:hover .featureLeftLine {
  height: 2px;
  background-color: white;
  width: 40px;
  position: absolute;
}


.featureLeftIcon .featureLeftLine {
  transition: width 200ms ease, background-color 300ms ease;
  height: 2px;
  width: 0px;
}

.featureLeftIcon:hover {
  width: 70px;
  background-color: #000;
  transition: 0.3ms all;
}

/* body {
    font-family: 'Objectivity', sans-serif; 
    letter-spacing: -3%; }
 */



.hoverBox:hover {
  background-color: #F8F8F8;
}

.hoverBox:hover .text-change {
  color: #F6B000;
}

.hoverBox:hover .text-img1 {
  display: none;
}

.hoverBox:hover .text-img2 {
  display: block !important;
}

.form-steps {
  display: flex;
  align-items: center;
  /* gap: 10px; */
  color: #B9B9B9;
  font-family: Objectivity;
  font-size: 14px;
  font-weight: 500;
  line-height: 14.35px;
  letter-spacing: -0.03em;

}

.form-steps span {
  padding: 10px 15px;
  border-radius: 5px;
  /* font-weight: bold; */
}

.form-steps .active {

  color: #0E0E0E;
  font-weight: 500;
}

.form-steps .completed {
  color: #0E0E0E;
  font-weight: 500;

}

.form-navigation button {
  margin: 10px;
  width: 100%;
  background-color: #000;
  /* Button color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-navigation button:hover {
  background-color: #000;
  /* Darker button color on hover */
}

.about .mainimagehero {
  background-image: url("../src/images/image about us.png");
  background-size: cover;
}

.ourteam .mainimagehero {
  background-image: url("../src/images/Mask group (3).png");
  background-size: cover;
}
.special .mainimagehero {
  background-image: url("../src/images/Mask group (4).png");
  background-size: cover;
}

@media only screen and (max-width: 1400px) {
  .hoverAni56:hover div {
    height: 2px;
    width: 32px;
    background-color: #F6B000;
    position: absolute;
    left: 62%;
  }

  .hoverAni1:hover img, .hoverAni1:hover svg {
    margin-left: 39px;
  }
}



@media only screen and (max-width: 600px) {
  .ourteam .mainimagehero {
    height: 640px !important;
    background-image: url("../src/images/bg-ourteam.png");
    background-size: cover;
  }

  .about .mainimagehero {
    height: 640px !important;
    background-image: url("../src/images/image\ 170.png");
    background-size: cover;
  }
  .special .mainimagehero {
    height: 690px !important;
    background-image: url("../src/images/photo.png");
    background-size: cover;
  }
  .form-steps span{
    padding: 10px 5px;
  }

  .catCard:hover .textSection {
    /* margin-left: -20px; */
  }

  .hoverAni56:hover div {
    height: 2px;
    width: 30px;
    background-color: #F6B000;
    position: absolute;
    left: 63.2%;
  }
}

