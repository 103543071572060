/* Add font imports */

@font-face {
  font-family: 'Objectivity';
  src:
    url('./fonts/Objectivity-Bold.woff') format('woff');
  font-weight: 700;
}


@font-face {
  font-family: 'Objectivity';
  src:
    url('./fonts/Objectivity-Medium.woff') format('woff');
  font-weight: 500;
  /* letter-spacing: -1px !important; */
  font-style: normal;
}



/* Repeat @font-face for each font file */
/* Example for another font */
@font-face {
  font-family: 'Objectivity';
  /* Replace with actual font name */
  src:
    url('./fonts/Objectivity-Regular.woff') format('woff');
  font-weight: normal;
  /* Adjust as needed */
  font-style: normal;
  
  /* Adjust as needed */
}

body {
  font-family: 'Objectivity', sans-serif; /* Fallback to sans-serif */
  /* max-width: 100vw; */
  /* overflow: hidden; */
  /* letter-spacing: -1px; */
}

/* .swiper-wrapper {
  transition-timing-function: linear !important;
} */



.class-for-h1{
  font-family: Objectivity;
  font-size: 60px;
  font-weight: 700;
  line-height: 75px;
  letter-spacing: -0.02em;
 
}
.class-for-h2{
font-family: Objectivity;
font-size: 45px;
font-weight: 700;
line-height: 56.25px;
letter-spacing: -0.02em;

}
.class-for-h3{
  font-family: Objectivity;
  font-size: 36px;
  font-weight: 700;
  line-height: 56.25px;
  letter-spacing: -0.02em;
  
  }
  .class-for-h4{
    
font-family: Objectivity;
font-size: 22px;
font-weight: 700;
line-height: 27.5px;
letter-spacing: -0.02em;
text-align: left;

  }
.class-for-h5{
  
font-family: Objectivity;
font-size: 18px;
font-weight: 700;
line-height: 22.5px;
letter-spacing: -0.02em;
text-align: left;

}
.class-for-p{
font-family: Objectivity;
font-size: 16px;
font-weight: 500;
line-height: 24.8px;
letter-spacing: -0.03em;
color: #959595;

}


.blogContainer h2 {
  transition: text-decoration 0.3s;
}

.blogContainer:hover h2 {
  text-decoration: underline;
}

.buttonblog{
  background-color: #F8F8F8;
  padding: 10px;
  color: black;
  width: 47px;
  height: 47px;
  transition: 0.5s;
  border-radius: 50%;
}
.buttonblog:hover{
  background-color: #000;
  padding: 10px;
  color: #ffffff;
  width: 47px;
  height: 47px;
}
.buttonblog1{
  background-color: #F8F8F8;
  padding: 10px;
  color: black;
  width: 47px;
  height: 47px;
  color: #959595;
  transition: 0.5s;
  border-radius: 50%;
  margin: 0 10px;
}
.buttonblog1:hover{
  background-color: #000;
  padding: 10px;
  color: #ffffff;
  width: 47px;
  height: 47px;
}
.cardetailpara p{
  padding-top: 30px;
  width: 58%;
}

.carDetailsImages .swiper {
  display: none;
}

.filter-datepickers .react-datepicker-wrapper {
  width: 100%;
}

#filterSection::-webkit-scrollbar {
  display: none;
}

#filterSection li {
  width: auto;
}

#filterSection .slider-container {
  background-color: transparent;
}

#filterSection  .price-range-slider {
  width: 100%;
  margin-left: 0;
}

#filterSection {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.vehicleModal::-webkit-scrollbar {
  display: none;
}

.vehicleModal {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media only screen and (max-width: 600px){
  .carDetailsImages .swiper {
    display: block;
  }  

  .class-for-h1{
    font-family: Objectivity;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: -0.02em;
   
  }
  .class-for-h2{
  font-family: Objectivity;
  font-size: 25px;
  font-weight: 700;
  line-height: 31.25px;
  letter-spacing: -0.02em;
  
  }
  .class-for-h3{
    font-family: Objectivity;
    font-size: 25px;
    font-weight: 700;
    line-height: 31.25px;
    letter-spacing: -0.02em;
    
    }
    .class-for-h5{
  
      font-family: Objectivity;
      font-size: 16px;
      font-weight: 700;
      line-height: 22.5px;
      letter-spacing: -0.02em;
      text-align: left;
      
      }
      .cardetailpara p{
        padding-top: 20px;
        width: 100%;
      }
}